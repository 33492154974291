import React from 'react';
import ReactDOM from 'react-dom';

import {Gallery, Slider} from '../util/Gallery.jsx';

const imageUrls = [
  'images/2018-interior/result-1.jpg',
  'images/2017-ForestPath/Forest_Day.jpg',
  '/images/2017-Donuts/result1.jpg',
  'images/2017-neural-style/output/ALSACE_starry.jpg',
];

const texts = [
  {
    title: '3D CG Interior - Living Room',
    link: '/2018/05/29/blender-cycles-interior',
    subtitle: 'A PBR Work in Blender Cycles. >>'
  },
  {
    title: 'Unreal Engine 4  - Forest Path',
    link: '/2017/08/22/UE4-work-forest-path-with-panorama',
    subtitle: 'It Contains a Panorama. To View in 360 degrees. >>'
  },
  {
    title: '3D CG Object- The Donuts',
    link: '/2017/04/01/blender-cycles-render-donuts/',
    subtitle: 'A PBR Work in Blender Cycles. >>'
  },
  {
    title: 'Machine Learning - Neural Style Transfer',
    link: '/2017/06/27/image-style-transfer-using-neural-style',
    subtitle: 'Neural Network Generate a Bunch of Images itself. >>'
  },
]

const root = document.querySelector('#home-gallery');

ReactDOM.render(
  <Gallery images={imageUrls} texts={texts} /* seconds={5} */ />, // React for JSX. <XX /> react.createElement('XX')
  root
);