import React, { Component } from 'react';
import ReactDOM from 'react-dom'

import classnames from 'classnames';

import styles from './gallery.module.sass';

class Gallery extends Component{
  constructor(props) {
      super(props);

      this.state = {
          currentIdx: 0,
          timerMilliseconds: props.seconds ? props.seconds*1000 : 0, // 0 disable timer,after seconds, switch next image
      }
  }

  componentDidMount(){
    
    this.createTimer()
  }

  componentWillUnmount(){
    this.stopTimer()
  }

  tick(){
    this.updateCurrentIndex(1);
  }

  createTimer(){
    if(this.state.timerMilliseconds <= 0){
      return ; // no timer
    }

    this.timerID = setInterval(
      () => this.tick(),
      this.state.timerMilliseconds
    );
  }

  stopTimer(){
    clearInterval(this.timerID);
  }

  resetTimer(){
    clearInterval(this.timerID);
    this.createTimer()
  }

  render(){

    const jumpPoints = Array(this.props.images.length);
    for (let i = 0; i < this.props.images.length; i+=1){
      jumpPoints[i] = (
        <button 
          key={i}
          className={classnames({
            [styles['jump-point']]: true,
            [styles['jump-point-current']]: i == this.state.currentIdx
          })}
          onClick={() => {
            this.jumpTo(i);
          }}
        >
        {/* {i+1} */}
        </button>
      );
    }

    return (
      <div className={styles.gallery}>
        
        <Slider // the slider contains all items(images)
          ref={(ref)=>this.slider=ref} 
          images={this.props.images} 
          texts={this.props.texts}
          current={this.state.currentIdx}
          afterJumpTo={(i)=>{this.setState({currentIdx:i})}}
        />

        <div className={styles.overlay}>
          {/* previous and next image button */}
          <button 
            onClick={()=>this.userUpdateCurrentIndex(-1)} 
            className={styles.previous}>
            &lt;
          </button>
          <button 
            onClick={()=>this.userUpdateCurrentIndex(1)} 
            className={styles.next}>
            &gt;
          </button>

          {/* jump to the image */}
          <div className={[styles['jump-point-set']]}>
            {jumpPoints}
          </div>
        </div>
      </div>
    );
  }

  jumpTo(idx, reset=true){
    this.setState({
      currentIdx: idx
    });
    this.slider.jumpToNum(idx);

    if (reset) {this.resetTimer()}
  }
  userUpdateCurrentIndex(diff, reset=true){
    this.updateCurrentIndex(diff)
    
    if(reset){
      this.resetTimer()
    }
  }

  updateCurrentIndex(diff){
    const totalNum = this.props.images.length
    let currentIdx = this.state.currentIdx + diff
    if (currentIdx >= totalNum) {
      currentIdx -= totalNum
    }else if (currentIdx < 0){
      currentIdx += totalNum
    }
    this.jumpTo(currentIdx, false);

    this.setState({
      currentIdx: currentIdx
    });
    
  }
}

  
/**
 * The slider component that holds items(images).
 * It's draggable. User can drag and switch item.
 * 
 * The main implementation uses the actual DOM(original browser DOM, 
 * not react virtualDOM) heavly.
 * So there is the custom of `shouldComponentUpdate` which changed the render way of
 * react. React only re-render when the images changed now.
 */
class Slider extends Component{
  constructor(props){
    super(props);

    this.state = {
      images: props.images,
    }

    this.current = props.current // the current item idx
    this.threshold = 0.25;// drag distance large than threshold * itemWidth, jump to other item, else return current
    // fix this problem, bind to scope
    const methods = [
      'setSliderWidth', 'resizeHandler', 
      'touchstartHandler', 'touchmoveHandler', 'touchendHandler',
      'stableSlider',
      'mousedownHandler', 'mousemoveHandler', 'mouseupHandler', 'mouseleaveHandler',
    ];
    methods.forEach( (method)=>{
      if (!this[method]) {throw new Error(`${method} doesn't exist.`);}
      this[method] = this[method].bind(this);
    });
  }

  componentDidMount(){
    if (!this.sliderDOM) {this.sliderDOM = ReactDOM.findDOMNode(this);}
    const dom = this.sliderDOM;

    this.setSliderWidth();
    this.jumpToNum(this.current);

    // attach slider event: mouse, touch, window.resize.
    this.initDragData()

    window.addEventListener('resize', this.resizeHandler);
    dom.addEventListener('touchstart', this.touchstartHandler);
    dom.addEventListener('touchmove', this.touchmoveHandler);
    dom.addEventListener('touchend', this.touchendHandler);

    dom.addEventListener('mousedown', this.mousedownHandler);
    dom.addEventListener('mousemove', this.mousemoveHandler);
    dom.addEventListener('mouseup', this.mouseupHandler);
    dom.addEventListener('mouseleave', this.mouseleaveHandler);

  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.resizeHandler);
    dom.removeEventListener('touchstart', this.touchstartHandler);
    dom.removeEventListener('touchmove', this.touchmoveHandler);
    dom.removeEventListener('touchend', this.touchendHandler);

    dom.removeEventListener('mousedown', this.mousedownHandler);
    dom.removeEventListener('mousemove', this.mousemoveHandler);
    dom.removeEventListener('mouseup', this.mouseupHandler);
    dom.removeEventListener('mouseleave', this.mouseleaveHandler);
  }

  /**
   * Change the react rerender strategy. Only re-render when images changed.
   * @param {*} nextProps 
   * @param {*} nextState 
   */
  shouldComponentUpdate(nextProps,nextState){
    if (nextState.images == this.state.images) {
      return false;
    }
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  render(){
    console.log('render slider');
    const itemNum = this.state.images.length;

    const wrapperImgs = this.state.images.map( (img, idx) => {
      return (
        <div key={idx} 
          className={[styles['slider-item']]}
          style={{width: `${100/itemNum}%`}}
        >
          <a className={styles.text} 
            href={this.props.texts[idx].link}>
            <span>{this.props.texts[idx].title}</span>
            <span>{this.props.texts[idx].subtitle}</span>
          </a>

          <img
            className={classnames({
              [styles['img-current']]: (idx == this.state.currentIdx)
            })}

            src={img}
          /> 
        </div>
      );
    });
    return (
      <div 
        className={styles.slider} 
      >
        {wrapperImgs}
      </div>
    );
  }

  /**
   * The event handler when user's mouse click down.
   * The start point when the mouse drag. The implementation is similar to touch handler.
   * @param {EventTarget} evt : The mouse down event target.
   */
  mousedownHandler(evt){
    this.isUserDraging = true;

    this.drag.startX = evt.clientX;
    this.drag.startY = evt.clientY;
    // console.log(`clientX: ${evt.clientX}; pageX: ${evt.pageX}`);
  }

  mousemoveHandler(evt){
    // evt.stopPropagation();
    evt.preventDefault();
    if (this.isUserDraging) {
      this.drag.endX = evt.clientX;

      this.distanceToMove(this.drag.endX - this.drag.startX);
    }
  }

  mouseupHandler(evt){
    // evt.stopPropagation();
    this.isUserDraging = false;
    // reset clean Drag data
    if(this.drag.endX !== null && Math.abs(this.drag.endX - this.drag.startX) > 0){ // prevent single touch click.

      this.stableSlider();
    }
    this.initDragData();
  }

  /**
   * Handle the interrupt condition. e.g. move over other objects while drag and move.
   * To stop staying at the mouse moving state when drag and move.
   * Similar to the end of mouse drag event. stable slider and reset drag data.
   * @param {EventTarget} evt 
   */
  mouseleaveHandler(evt){
    if (this.isUserDraging) { // The interruption happens when drag.
      this.mouseupHandler(evt); // just invoke the `mouseupHandler` directly
    }
  }

  // clickHandler(evt){
  //   console.log(this.drag.preventLink)
  //   if(this.drag.preventLink){
  //     evt.preventDefault();
  //   }
  //   this.drag.preventLink = false; // reset after stop one click
  // }

  //touch events
  touchstartHandler(evt){
    //boolean flag whether user is dragin
    this.isUserDraging = true; 
    // record touch start point(horizal coordinate, x axis)
    this.drag.startX = evt.touches[0].pageX;
    this.drag.startY = evt.touches[0].pageY;

  }

  touchmoveHandler(evt){
    // evt.preventDefault();
    // evt.stopPropagation();
    const deltaX = evt.touches[0].pageX - this.drag.startX;
    const deltaY = evt.touches[0].pageY - this.drag.startY;
    
    /* the horizontal movement is large than the vertical one. 
      consider it is horizontal movement, and could triggle the drag and move */
    const isHorizontal = Math.abs(deltaX) >= Math.abs(deltaY) ;

    if (this.isUserDraging && isHorizontal) {
      this.drag.endX = evt.touches[0].pageX;
      this.distanceToMove(deltaX);
    }
  }

  touchendHandler(evt){
    this.isUserDraging = false;
    // reset clean Drag data
    if(this.drag.endX !== null && Math.abs(this.drag.endX - this.drag.startX) > 0){ // prevent single touch click.
      this.stableSlider();
    }
    this.initDragData();
  }

  initDragData(){
    //  TODO spilt init and reset. init just create object, reset only reset some values
    this.drag = {
      startX : null,
      endX : null,

      startY: null,
    }
  }

  /**
   * User grab operation would cause the intermediate slide state,
   * like the slider item stay half.
   * 
   * The method to stable the silder by handling 
   * after the user intermediate operation.
   */
  stableSlider(){
    // positive distance, drag to left; 
    const distance = this.drag.endX - this.drag.startX;

    if (Math.abs(distance) > this.threshold*this.itemWidth) {
      const diff = distance > 0 ? -1 : 1
      this.jumpToNum(this.current + diff);
    }else{
      this.jumpToNum(this.current);
    } 
  }

  /**
   * Move the slider by user
   * @param distance: the move distance(user drags offset)
   */
  distanceToMove(distance){
    const itemNum = this.state.images.length;
    // console.log(`percentage of distance to item: ${ distance/this.itemWidth}`);
    let percentage = (this.current/itemNum - distance/(this.itemWidth*itemNum)) * 100

    this.sliderDOM.style['transform'] = `translate(${-1 * percentage}%)`;
  }

  

  resizeHandler(){
    // update silder width when broswer window resize
    this.setSliderWidth()
  }

  jumpToNum(idx){
    const dom = this.sliderDOM;
    const itemNum = this.state.images.length;

    // make current index between 0 and len-1
    if (idx < 0) {
      idx = (idx % itemNum) + itemNum;
    } else if (idx >= itemNum) {
      idx = idx % itemNum;
    }

    // TODO transform webkit
    // move one step : move the percentage 1/itemNum
    dom.style['transform'] = `translate(${-1 * idx/itemNum * 100}%)`;

    this.current = idx;

    if (this.props.hasOwnProperty('afterJumpTo')) { // if function exists, call it
      this.props.afterJumpTo(idx); // callback function of the parent component
    }
  }

  setSliderWidth () {
    const dom = this.sliderDOM;
    const itemNum = this.state.images.length;

    const parent = dom.parentElement;
    const parentWidth = parent.offsetWidth;
    // update the single item width
    this.itemWidth = parentWidth;

    dom.style['width'] = `${parentWidth * itemNum}px`;
  }
}

export { Gallery, Slider };